import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EPropertyUrl } from 'antd/data/urls/property.url';
import {
  createPropertyConverter,
  getUnitsCoverter,
  propertiesGetConverter,
  propertiesConverter,
  propertiesTemplateTypeConverter,
  propertyCsvUploadConverter,
  propertyTypeConverter,
  propertyDetailsConverter,
  propertyManagersTableConverter,
  invitePropertyManagerConverter,
  propertyManagerPropertiesTableConverter,
  unassignedPropertiesConverter,
  assignePropertiesConverter,
  uploadPetChargeStatusesConverter,
  propertyUploadedFilesConverter,
  residentsScreeningActionConverter,
  addUnitsConverter,
} from 'antd/data/converters/property.converters';
import {
  EPropertiesTemplateTypeUI,
  TCreatePropertyUI,
  TPropertiesCSVUploadResultDB,
  TPropertiesCSVUploadResultUI,
  TPropertiesDB,
  TPropertiesUI,
  TPropertiesTypeDB,
  TPropertiesTypeUI,
  TPropertiesUploadUI,
  TPropertyDB,
  TPropertyTypeDB,
  TPropertyTypeUI,
  TPropertyUI,
  TUnitDB,
  TUnitUI,
  TUpdatePoilicyUI,
  TPropertyManagersTableUI,
  TPropertyManagersTableDB,
  TInvitePropertyManagerUI,
  TPropertyManagerPropertiesUI,
  TPropertyManagerPropertiesDB,
  TPropertyManagerDetailsUI,
  TUnassignedPropertiesUI,
  TUnassignedPropertiesDB,
  TAssignPropertyUI,
  TPetChargeStatusesUploadUI,
  TPreopertyDeletePreviewUI,
  TPropertyUploadedFilesUI,
  TPropertyUploadedFilesDB,
  TResidentsScreeningActionUI,
  TAddUnitsUI,
  TAssignedPropertiesDB,
  TAssignedPropertiesUI,
  ENotificationTypesDB,
} from 'antd/data/types/property.types';
import {
  TGetPropertyOnboardDetailsUI,
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
  TPropertyOnboardDetailsUI,
} from 'antd/data/types/pagableData.type';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import {
  TIntegrationResultProps,
  TIntegrationResultPropsUI,
  TIntegrationType,
  TResidentsPetChargesUploadDataUI,
} from 'antd/data/types/integration.types';
import { EIntegrationUrls } from 'antd/data/urls/integration.url';
import {
  uploadTenantsPetChargesCSVExecuteConverter,
  uploadTenantsPetChargesCSVPreviewConverter,
} from 'antd/data/converters/integration.converter';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}`;
export const propertyApi = authSplitApi('propertyApi', [
  'propertiesSearch',
  'units',
  'propertyTypes',
  'properties',
  'property',
  'propertyManagers',
  'propertyManagerDetails',
  'propertyUploadedFiles',
  'assignedProperties',
  'unassignedProperties',
]).injectEndpoints({
  endpoints: (build) => ({
    getPropertiesSearch: build.query<TPropertyUI[], { [key: string]: string | undefined } | null | void>({
      query(params) {
        const paramsCopy = { ...params } as {
          [key: string]: string | undefined;
        } | null | void;
        if (paramsCopy?.state && paramsCopy?.state === 'MULTI') {
          paramsCopy.state = undefined;
        }
        return {
          url: `${URL}/${EPropertyUrl.SEARCH}`,
          method: 'GET',
          params: { ...paramsCopy },
        };
      },
      transformResponse: (data: TPropertyDB[]) => data?.map((item) => propertiesGetConverter.fromDb(item)) || [],
      providesTags: ['propertiesSearch'], // for caching and update cache
    }),
    getUnits: build.query<TUnitUI[], { propertyId: string }>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.UNITS}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TUnitDB[]) => data?.map((item) => getUnitsCoverter.fromDb(item)) || [],
      providesTags: ['units'],
    }),
    getPropertyTypes: build.query<TPropertyTypeUI[], string>({
      query() {
        return {
          url: `${URL}/${EPropertyUrl.TYPES}`,
          method: 'GET',
        };
      },
      providesTags: ['propertyTypes'],
      transformResponse: (data: TPropertyTypeDB[]) => data.map((item) => propertyTypeConverter.fromDb(item)),
    }),
    uploadPropertiesCSV: build.mutation<TPropertiesCSVUploadResultUI, TPropertiesUploadUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.UPLOAD}`,
          method: 'POST',
          body: propertyCsvUploadConverter.toDb(body),
        };
      },
      transformResponse: (data: TPropertiesCSVUploadResultDB) => propertyCsvUploadConverter.fromDb(data),
    }),
    previewCSVUploadResult: build.mutation<TPropertiesCSVUploadResultUI, TPropertiesUploadUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.PREVIEW_UPLOAD}`,
          method: 'POST',
          body: propertyCsvUploadConverter.toDb(body),
        };
      },
      transformResponse: (data: TPropertiesCSVUploadResultDB) => propertyCsvUploadConverter.fromDb(data),
    }),
    residentsPetChargesUploadPreview: build.mutation<TIntegrationResultPropsUI, TResidentsPetChargesUploadDataUI>({
      query(body) {
        return {
          url: `${URL}/${EIntegrationUrls.IMPORT}/${EIntegrationUrls.TENANTS_PET_CHARGES}/${EIntegrationUrls.PREVIEW}`,
          method: 'POST',
          body: uploadTenantsPetChargesCSVPreviewConverter.toDb(body),
        };
      },
      transformResponse: (data: TIntegrationResultProps) => uploadTenantsPetChargesCSVPreviewConverter.fromDb(data),
    }),
    residentsPetChargesUploadExecute: build.mutation<void, TResidentsPetChargesUploadDataUI>({
      query(body) {
        return {
          url: `${URL}/${EIntegrationUrls.IMPORT}/${EIntegrationUrls.TENANTS_PET_CHARGES}/${EIntegrationUrls.EXECUTE}`,
          method: 'POST',
          body: uploadTenantsPetChargesCSVExecuteConverter.toDb(body),
        };
      },
    }),
    getCSVTemplate: build.query<{ url: string }, { type: EPropertiesTemplateTypeUI }>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.TEMPLATE}`,
          method: 'GET',
          params: {
            ...params,
            type: propertiesTemplateTypeConverter.toDb(params.type),
          },
        };
      },
    }),
    createProperty: build.mutation<string, TCreatePropertyUI>({
      query(body) {
        return {
          url: `${URL}`,
          method: 'POST',
          body: createPropertyConverter.toDb(body),
        };
      },
      invalidatesTags: ['properties', 'assignedProperties'],
    }),
    updatePolicy: build.mutation<void, TUpdatePoilicyUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.POLICY}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['property'],
    }),
    getProperty: build.query<TPropertiesTypeUI, { id: string }>({
      query({ id }) {
        return {
          url: `${URL}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPropertiesTypeDB) => propertyDetailsConverter.fromDb(data),
      providesTags: ['property'],
    }),
    getProperties: build.query<TPageableDataWithContentUi<TPropertiesUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${URL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}, true),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TPropertiesDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => propertiesConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['properties'],
    }),
    deleteProperty: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${URL}/${id}`,
          method: 'DELETE',
        };
      },
    }),
    changePropertyName: build.mutation<void, { propertyId: string; name: string }>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.NAME}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['property'],
    }),
    changePropertyImage: build.mutation<void, { propertyId: string; photo: string }>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.PHOTO}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['property'],
    }),
    importPetChargeStatuses: build.mutation<void, TPetChargeStatusesUploadUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.PET_CHARGES}`,
          method: 'POST',
          body: uploadPetChargeStatusesConverter.toDb(body),
        };
      },
      invalidatesTags: ['property', 'propertyUploadedFiles'],
    }),
    getPetChargesCSVTemplate: build.query<{ url: string; name: string }, TIntegrationType>({
      query(type) {
        return {
          url: `${URL}/${EPropertyUrl.PET_CHARGES}/${EIntegrationUrls.TEMPLATE}`,
          method: 'GET',
          params: { integrationType: type },
        };
      },
    }),
    getPropertyOnboardDetails: build.query<TPropertyOnboardDetailsUI, TGetPropertyOnboardDetailsUI>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.ONBOARD}/${EPropertyUrl.DETAILS}`,
          method: 'GET',
          params,
        };
      },
    }),
    onboardProperty: build.mutation<void, { propertyId: string }>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.ONBOARD}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['property'],
    }),
    getPropertyManagers: build.query<TPageableDataWithContentUi<TPropertyManagersTableUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TPropertyManagersTableDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => propertyManagersTableConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['propertyManagers'],
    }),
    invitePropertyManager: build.mutation<void, TInvitePropertyManagerUI>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.INVITATION}`,
          method: 'POST',
          body: invitePropertyManagerConverter.toDb(body),
        };
      },
      invalidatesTags: ['propertyManagers'],
    }),
    reInviteProperyManager: build.mutation<void, { propertyManagerId: string }>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.INVITATION}/${EPropertyUrl.RESEND}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagers'],
    }),
    deleteProperyManager: build.mutation<void, { propertyManagerId: string }>({
      query({ propertyManagerId }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${propertyManagerId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['propertyManagers'],
    }),
    getPropertyManagersAssigned: build.query<
      TPageableDataWithContentUi<TPropertyManagerPropertiesUI[]>,
      TPageableDataParamUI & { propertyManagerId: string }
    >({
      query({ search, pagination, propertyManagerId }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.ASSIGNED}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            propertyManagerId,
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TPropertyManagerPropertiesDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => propertyManagerPropertiesTableConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['propertyManagerDetails'],
    }),
    getPropertyManagerDetails: build.query<TPropertyManagerDetailsUI, { id: string }>({
      query({ id }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['propertyManagerDetails'],
    }),
    unassignProperty: build.mutation<void, { properties: string[]; propertyManagerId: string }>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.UNASSIGN}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['assignedProperties', 'propertyManagerDetails', 'unassignedProperties'],
    }),
    getUnassignedProperties: build.query<TUnassignedPropertiesUI[], { propertyManagerId: string }>({
      query(params) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.UNASSIGNED}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['unassignedProperties'],
      transformResponse: (data: TUnassignedPropertiesDB[]) =>
        (data || []).map((item) => unassignedPropertiesConverter.fromDb(item)),
    }),
    assignProperty: build.mutation<void, TAssignPropertyUI>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.ASSIGN}`,
          method: 'PATCH',
          body: assignePropertiesConverter.toDb(body),
        };
      },
      invalidatesTags: ['propertyManagerDetails', 'assignedProperties'],
    }),
    getDeletedPropertyPreview: build.query<TPreopertyDeletePreviewUI, { id: string }>({
      query({ id }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.DELETE}/${EPropertyUrl.PREVIEW}/${id}`,
          method: 'GET',
        };
      },
    }),
    getPropertyUploadedFiles: build.query<TPageableDataWithContentUi<TPropertyUploadedFilesUI[]>, TPageableDataParamUI>(
      {
        query({ search, pagination, queryParams }) {
          return {
            url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.UPLOADED_FILES}`,
            method: 'GET',
            params: queryParamConverter(search, pagination, queryParams),
          };
        },
        providesTags: ['propertyUploadedFiles'],
        transformResponse: (data: TPageableDataWithContent<TPropertyUploadedFilesDB[]>) => {
          const { content, ...other } = data;
          return {
            content: data.content.map((item) => propertyUploadedFilesConverter.fromDb(item)),
            paginationData: pageableDataConverter.fromDb(other),
          };
        },
      }
    ),
    residentsScreeningAction: build.mutation<void, TResidentsScreeningActionUI>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.TENANTS_SCREENING}`,
          method: 'PATCH',
          body: residentsScreeningActionConverter.toDb(body),
        };
      },
      invalidatesTags: ['property'],
    }),
    updateAccessToFuture: build.mutation<void, { hasAccessToFuture: boolean; propertyManagerId: string }>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.FUTURE_PROPERTIES}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
    addUnits: build.mutation<void, TAddUnitsUI>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${body.propertyId}/${EPropertyUrl.UNIT}`,
          method: 'POST',
          body: addUnitsConverter.toDb(body),
        };
      },
      invalidatesTags: ['propertyManagers'],
    }),
    updateNotificationsToFuture: build.mutation<
      void,
      {
        notificationsEnabledForFutureProperties: boolean;
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.FUTURE_PROPERTIES_NOTIFICATIONS}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
    subscribeToNotification: build.mutation<
      void,
      {
        notificationTypes: ENotificationTypesDB[];
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.NOTIFICATION}/${EPropertyUrl.SUBSCRIBE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
    unsubscribeFromNotification: build.mutation<
      void,
      {
        notificationTypes: ENotificationTypesDB[];
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.NOTIFICATION}/${EPropertyUrl.UNSUBSCRIBE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
    enableNotificationsForProperties: build.mutation<
      void,
      {
        properties: string[];
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.NOTIFICATIONS}/${EPropertyUrl.ENABLE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails', 'assignedProperties'],
    }),
    disableNotificationsForProperties: build.mutation<
      void,
      {
        properties: string[];
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.PROPERTIES}/${EPropertyUrl.NOTIFICATIONS}/${EPropertyUrl.DISABLE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails', 'assignedProperties'],
    }),
    markAsPrimaryContact: build.mutation<
      void,
      {
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.CONACT}/${EPropertyUrl.PRIMARY}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
    markAsNonPrimaryContact: build.mutation<
      void,
      {
        propertyManagerId: string;
      }
    >({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTY_MANAGERS}/${EPropertyUrl.CONACT}/${EPropertyUrl.NON_PRIMARY}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['propertyManagerDetails'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertyUploadedFilesQuery,
  useGetPropertiesSearchQuery,
  useGetUnitsQuery,
  useGetPropertyTypesQuery,
  useGetCSVTemplateQuery,
  useUploadPropertiesCSVMutation,
  usePreviewCSVUploadResultMutation,
  useCreatePropertyMutation,
  useUpdatePolicyMutation,
  useGetPropertyQuery,
  useGetPropertiesQuery,
  useDeletePropertyMutation,
  useChangePropertyNameMutation,
  useChangePropertyImageMutation,
  useGetPropertyManagersQuery,
  useInvitePropertyManagerMutation,
  useReInviteProperyManagerMutation,
  useDeleteProperyManagerMutation,
  useGetPropertyManagersAssignedQuery,
  useGetPropertyManagerDetailsQuery,
  useUnassignPropertyMutation,
  useGetUnassignedPropertiesQuery,
  useAssignPropertyMutation,
  useGetPropertyOnboardDetailsQuery,
  useOnboardPropertyMutation,
  useImportPetChargeStatusesMutation,
  useGetPetChargesCSVTemplateQuery,
  useGetDeletedPropertyPreviewQuery,
  useResidentsScreeningActionMutation,
  useResidentsPetChargesUploadExecuteMutation,
  useResidentsPetChargesUploadPreviewMutation,
  useUpdateAccessToFutureMutation,
  useAddUnitsMutation,
  useUpdateNotificationsToFutureMutation,
  useSubscribeToNotificationMutation,
  useUnsubscribeFromNotificationMutation,
  useEnableNotificationsForPropertiesMutation,
  useDisableNotificationsForPropertiesMutation,
  useMarkAsNonPrimaryContactMutation,
  useMarkAsPrimaryContactMutation,
} = propertyApi;
