import { TMultiOptionTypeMaterialUI, TOptionType, TOptionTypeDB, TOptionTypeUi } from './general.types';
import { EPetStatusDB } from './residence.types';

export type TPropertyDB = TOptionTypeDB;

export type TPropertyUI = TMultiOptionTypeMaterialUI;

export type TUnitDB = {
  id: string;
  number: string;
};

export type TUnitUI = TMultiOptionTypeMaterialUI;

export type TPropertyTypeUI = {
  title: string;
  keyWord: string;
};
export type TPropertyTypeDB = {
  key: string;
  displayName: string;
};

export enum EPropertiesTemplateTypeDB {
  'OPP' = 'OPP',
}

export enum EPropertiesTemplateTypeUI {
  'OPP' = 'OPP',
}

export type TPropertiesUploadDB = {
  type: EPropertiesTemplateTypeDB;
  file: File[];
};

export type TPropertiesUploadUI = {
  type: EPropertiesTemplateTypeUI;
  file: File[];
};

export type TPropertiesCSVUploadResultUI = {
  skippedProperties: Blob | null;
  skippedPropertiesCount: number;
  removedPropertiesCount: number;
  addedPropertiesCount: number;
  matchedPropertiesCount: number;
  totalPropertiesCount: number;
};

export type TPropertiesCSVUploadResultDB = {
  skippedProperties: string;
  skippedPropertiesCount: number;
  removedPropertiesCount: number;
  addedPropertiesCount: number;
  matchedPropertiesCount: number;
  totalPropertiesCount: number;
};

export enum EPropertySourceDB {
  SYSTEM = 'SYSTEM',
  RESMAN = 'RESMAN',
}
export enum EPropertySourceUI {
  SYSTEM = 'System Created',
  RESMAN = 'Imp. from ResMan',
}
export enum EPropertyStatusDB {
  ACTIVE = 'Active',
  PENDING = 'Invited',
}
export enum EPropertyStatusUI {
  PENDING = 'Pending',
  ACTIVE = 'Active',
}
export type TPropertyStatisticsDB = {
  esaCounts: number;
  petsCounts: number;
  residentsCounts: number;
  saCounts: number;
  unitsCountWithAnimal: number;
};
export type TPropertyStatisticsUI = {
  esaCounts: number;
  petsCounts: number;
  residentsCounts: number;
  saCounts: number;
  unitsCountWithAnimal: number;
};
export type TPropertiesTypeDB = {
  id: string;
  name: string;
  photoUrl: string | null;
  unitCount: number;
  type: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  source: EPropertySourceDB;
  status: string;
  unitNumbers: string[];
  defaultPolicy: {
    id: string;
    leasePacketCode?: string;
    name: string;
  };
  petsAllowed: boolean;
  userId: string;
  resourceId: string;
  propertyInternalId: number;
  isOnboarded: boolean;
  propertyStatistics: TPropertyStatisticsDB;
  lastSyncDate: string | null;
  lastFileExportDate: string | null;
  isScreeningEnabled: boolean;
  canEditScreenExistingTenantsBeforeOnboarding: boolean;
};
export type TPropertiesTypeUI = {
  id: string;
  name: string;
  photoUrl?: string | null;
  unitCount: number;
  type: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  source: EPropertySourceUI;
  status: string;
  unitNumbers: string[];
  defaultPolicy: {
    id: string;
    leasePacketCode?: string;
    policyName: string;
  };
  petsAllowed: boolean;
  userId: string;
  resourceId: string;
  propertyInternalId: number;
  isOnboarded: boolean;
  propertyStatistics: TPropertyStatisticsUI;
  lastSyncDate: string | null;
  lastFileExportDate: string | null;
  isScreeningEnabled: boolean;
  canEditScreenExistingTenantsBeforeOnboarding: boolean;
};

export type TCreatePropertyDB = {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  zip: string;
  state: string;
  status: string;
  unitCount: number;
  unitNumbers: string[];
  type: string;
  userId: string;
  defaultPolicyId?: string;
  photoUrl?: string;
  screenExistingTenants: boolean;
};
export type TCreatePropertyUI = {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  zip: string;
  state: string;
  status: string;
  unitCount: number;
  unitNumbers: string[];
  type: string;
  userId: string;
  defaultPolicyId?: string;
  photoUrl?: string;
  screenExistingTenants: boolean;
};

export type TUpdatePoilicyDB = {
  policyId: string;
  propertyId: string;
};
export type TUpdatePoilicyUI = {
  policyId: string;
  propertyId: string;
};

export type TPropertiesDB = {
  id: string;
  name: string;
  photoUrl?: string | null;
  unitCount: number;
  type: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  source: EPropertySourceDB;
  defaultPolicy: {
    id: string;
    petsAllowed: boolean;
    policyName: string;
  };
  status: EPropertyStatusDB;
};

export type TPropertiesUI = {
  id: string;
  name: string;
  photoUrl?: string | null;
  unitCount: number;
  type: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  source: EPropertySourceUI;
  defaultPolicy: {
    id: string;
    petsAllowed: boolean;
    policyName: string;
  };
  status: EPropertyStatusUI;
};

export type TPropertyManagersTableDB = {
  allowedDelete: boolean;
  allowedReInvite: boolean;
  email: string;
  id: string;
  name: string;
  phone: string;
  position: string;
  profilePicture: string | null;
  propertiesCount: number;
  status: EPropertyStatusDB;
};
export type TPropertyManagersTableUI = {
  allowedDelete: boolean;
  allowedReInvite: boolean;
  email: string;
  id: string;
  name: string;
  phone: string;
  position: string;
  profilePicture: string | null;
  propertiesCount: number;
  status: EPropertyStatusUI;
};

export type TInvitePropertyMangerDB = {
  email: string;
  name: string;
  phone: string;
  position: TOptionType;
  properties?: string[];
  accessToFutureProperties: boolean;
};

export type TInvitePropertyManagerUI = {
  email: string;
  name: string;
  phone: string;
  position: TOptionTypeUi;
  properties: TOptionTypeUi[];
  accessToFutureProperties: boolean;
};

export type TPropertyManagerPropertiesDB = {
  address1: string;
  id: string;
  type: string;
  unitCount: number;
  name: string;
  notificationEnabled: boolean;
};

export type TPropertyManagerPropertiesUI = {
  address1: string;
  id: string;
  type: string;
  unitCount: number;
  name: string;
  notificationEnabled: boolean;
};

export type TAddUnitsDB = {
  propertyId: string;
  unitNumbers: string[];
};
export type TAddUnitsUI = {
  propertyId: string;
  unitNumbers: string[];
};

export type TPropertyManagerDetailsValueDB = {
  value?: string;
  allowedOPerations: string[];
};
export type TPropertyManagerDetailsValueUI = {
  value?: string;
  allowedOPerations: string[];
};
export type TPropertyManagerDetailsBooleanValueDB = {
  value?: boolean;
  allowedOPerations: string[];
};
export type TPropertyManagerDetailsBooleanValueUI = {
  value?: boolean;
  allowedOPerations: string[];
};

export enum ENotificationTypesDB {
  ANIMAL_APPLICATION_APPROVED = 'ANIMAL_APPLICATION_APPROVED',
  TENANT_RESIDENCY_CREATED = 'TENANT_RESIDENCY_CREATED',
  ANIMAL_APPLICATION_REJECTED = 'ANIMAL_APPLICATION_REJECTED',
  ANIMAL_APPLICATION_MISSING_DATA_PROVIDED = 'ANIMAL_APPLICATION_MISSING_DATA_PROVIDED',
  ANIMAL_APPLICATION_SUBMITTED = 'ANIMAL_APPLICATION_SUBMITTED',
  REPORT_PM_TODO = 'REPORT_PM_TODO',
}
export enum ENotificationTypesUI {
  TENANT_RESIDENCY_CREATED = 'Tenant Application/Residency Create',
  ANIMAL_APPLICATION_SUBMITTED = 'Animal Application Submit',
  ANIMAL_APPLICATION_MISSING_DATA_PROVIDED = 'Animal Application Missing Data Provide',
  ANIMAL_APPLICATION_APPROVED = 'Animal Application Approve',
  ANIMAL_APPLICATION_REJECTED = 'Animal Application Not Approve',
  REPORT_PM_TODO = 'Daily To-Do Update',
}

export type TPropertyManagerDetailsDB = {
  email: TPropertyManagerDetailsValueDB;
  id: string;
  name: TPropertyManagerDetailsValueDB;
  phone: TPropertyManagerDetailsValueDB;
  position: TPropertyManagerDetailsValueDB;
  propertyManagementId: string;
  properyManagementName: TPropertyManagerDetailsValueDB;
  hasAccessToFutureProperties: TPropertyManagerDetailsBooleanValueDB;
  notificationsEnabledForFutureProperties: TPropertyManagerDetailsBooleanValueDB;
  notifications: ENotificationTypesDB[];
  primaryContact: TPropertyManagerDetailsBooleanValueDB;
};
export type TPropertyManagerDetailsUI = {
  notifications: ENotificationTypesDB[];
  email: TPropertyManagerDetailsValueUI;
  id: string;
  name: TPropertyManagerDetailsValueUI;
  phone: TPropertyManagerDetailsValueUI;
  position: TPropertyManagerDetailsValueUI;
  propertyManagementId: string;
  properyManagementName: TPropertyManagerDetailsValueUI;
  hasAccessToFutureProperties: TPropertyManagerDetailsBooleanValueUI;
  notificationsEnabledForFutureProperties: TPropertyManagerDetailsBooleanValueUI;
  primaryContact: TPropertyManagerDetailsBooleanValueUI;
};

export type TAssignedPropertiesDB = {
  id: string;
  name: string;
  notificationEnabled: boolean;
};
export type TAssignedPropertiesUI = {
  value: string;
  label: string;
  notificationEnabled: boolean;
};

export type TUnassignedPropertiesDB = {
  id: string;
  name: string;
};
export type TUnassignedPropertiesUI = {
  value: string;
  label: string;
};

export type TAssignPropertyDB = {
  properties: string[];
  propertyManagerId: string;
};
export type TAssignPropertyUI = {
  properties: TOptionTypeUi[];
  propertyManagerId: string;
};

export type TPetChargeStatusesUploadUI = {
  propertyId: string;
  type: TOptionTypeUi;
  files: File[];
};
export type TPetChargeStatusesUploadDB = FormData;

export type TPreopertyDeletePreviewDB = {
  moveOutResidencyCount: number;
  moveOutAnimalCount: number;
  retractedTenantApplicationCount: number;
  retractedAnimalApplicationCount: number;
};

export type TPreopertyDeletePreviewUI = {
  moveOutResidencyCount: number;
  moveOutAnimalCount: number;
  retractedTenantApplicationCount: number;
  retractedAnimalApplicationCount: number;
};

export type TPropertyUploadedFilesDB = {
  id: string;
  fileUrl: string;
  fileName: string;
  type: EUploadedFileTypeDB;
  exportTime: string;
  uploadTime: string;
  uploaderId: string;
  uploaderName: string;
};

export type TPropertyUploadedFilesUI = {
  id: string;
  fileUrl: string;
  fileName: string;
  type: EUploadedFileTypeUI;
  exportTime: string;
  uploadTime: string;
  uploaderId: string;
  uploaderName: string;
};

export enum EUploadedFileTypeUI {
  PET_CHARGES = 'Pet Charges',
  APPLICANTS = 'Applicants',
  RESIDENTS = 'Residents',
}
export enum EUploadedFileTypeDB {
  PET_CHARGES = 'PET_CHARGES',
  APPLICANTS = 'APPLICANTS',
  RESIDENTS = 'RESIDENTS',
}

export type TResidentsScreeningActionUI = {
  propertyId: string;
  needToScreen: boolean;
};
export type TResidentsScreeningActionDB = {
  propertyId: string;
  needToScreen: boolean;
};
